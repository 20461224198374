// Colors
// ======

$black-10: darken(#fff, 10%);
$black-20: darken(#fff, 20%);
$black-30: darken(#fff, 30%);
$black-40: darken(#fff, 40%);
$black-50: darken(#fff, 50%);
$black-60: darken(#fff, 60%);
$black-70: darken(#fff, 70%);
$black-80: darken(#fff, 80%);
$black-90: darken(#fff, 90%);
$white: #fff;
$blue: #229ED0; // #1D76BC
$blue-dark: #4773B9;
$blue-light: #66A2DC;
// Base Measurement Unit
// $base-unit: 6px;

// Typography Variables
// ====================

$basefont: 12;
$h1: 24;
$h1-narrow: 21;
$h2: 21;
$h3: 18;
$h4: 14;
$h5: 12;
$h6: 10;
$basep: $basefont;

$light: 100;
$regular: 300;
$semi-bold: 400;
$heavy: 700;
$black: 900;

$line-height: 1.5;

$body-bg: #fff;
$body-font-color: $black-80;
$body-font-family: Verdana,Arial,Helvetica,sans-serif;
$body-font-weight: 400;
$ff-primary: $body-font-family;
$ff-secondary: Georgia, serif;

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with browser-based text zoom or user-set defaults.
$base-font-size: 100% !default;

// Font-smoothing
$font-smoothing: antialiased;

// Text direction settings
$text-direction: ltr;

//margins & padding
// $space: ($base-unit * 2);

// Links
$link-color: $black-70;
$link-color-hover: darken($link-color, 15%);

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 600;

/* Grid settings
========================== */
$susy: (
  columns: 6,
  gutters: .5,
  math: fluid,
  output: float,
  // debug: (image: show)
);

$layout-medium: layout(12 1/2 false); // 12 columns, 1/3 gutter

// Spacing
$space: 16px;
$space-one-fourth: $space * 0.25;
$space-one-third: $space * 0.33333;
$space-half: $space * 0.5;
$space-two-third: $space * 0.66666;
$space-double: $space * 2;
$space-triple: $space * 3;
$space-quadruple: $space * 4;


// Breakpoints
$break-xsmall: 16em; // 400px
$break-small: 26em; // 400px
$break-medium: 34em; 
$break-large: 47em; 
$break-xlarge: 64em;
