.btn {
  border: none;
  padding: .25em 1em;
  display: inline-block;
}

.btn--cta {
  @extend .btn;
  // background: linear-gradient(to bottom, $blue-dark, $blue-light);
  background-color: $blue;
  color: $white;

  &:hover,
  &:focus {
    background: scale_color($blue, $lightness: 10%);
  }
}