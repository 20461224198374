.hero {
  .title {
    font-size: em($h1-narrow);
    font-weight: $fw-bold;
    text-align: center;
    margin-top: $space-double;
    margin-bottom: $space-double;
    clear: none;

    @include breakpoint($break-medium) {
      font-size: em($h1);
      margin-top: $space-quadruple;
      margin-bottom: $space-quadruple;
    }
  }
}

.hero--img,
.hero--video {
  @extend .hero;

  .title {
    margin-top: $space-half;

    @include breakpoint($break-medium) {
      margin-top: $space;
      margin-bottom: $space;
    }
  }
}

.hero--img {
  position: relative;
  overflow: hidden;
  
  @include breakpoint($break-medium) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,.2);
      z-index: 5;
    }
  }

  .text-wrap {
    position: relative;
    
    @include breakpoint($break-medium) {
      z-index: 10;
      padding: $space-quadruple 10%;
      color: $white;
    }
  }

  .title {
    color: inherit;
  }

  img {
    @include breakpoint($break-medium) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}

.hero--img-only {}

.hero--video {
  .video {
    @include breakpoint($break-large) {
      @include span(5 $layout-medium last);
      margin-top: $space;
      margin-bottom: $space;
    }
  }

  .title {
    @include breakpoint($break-large) {
      font-size: em($h2);
      @include span(6 $layout-medium);
    }
  }
}