.section-row {
  @extend %clearfix;
}

.section-main {
  @extend %clearfix;
}

.section-footer {
  // @extend %clearfix;
}

.section-row,
.field-type-entityreference {
  + .section-row,
  + .field-type-entityreference {
    margin-top: $space;
    @include breakpoint($break-medium) {
      margin-top: $space-double;
    }
  }
}