// Logo
.logo {
  text-align: center;
  display: block;
  line-height: 70px;
  transition: all .2s ease-in-out;
  
  img {
    max-width: 80px;
    max-height: 70px;
    vertical-align: middle;
  }
}

a.logo {
  &:hover,
  &:focus {
    transform: scale(1.2);
  }
}

// Video
.video {
  .title {
    font-size: em($h4);
    font-weight: $fw-bold;
    margin: 1em 0 .25em;
  }

  .meta {
    margin: 0 0 .75em;
    font-size: em($h6);
  }
}

.video--large {
  @extend .video;
  text-align: center;
  
  .title,
  .desc {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    @include breakpoint($break-medium) {
      max-width: 95%;
    }
  }

  .title {
    color: #000;
    font-size: em($h3);
    margin-top: 1em !important;
    margin-bottom: .25em;
    
    @include breakpoint($break-medium) {
      font-size: em($h2);
    }
  }
  .desc {
    font-size: em($h5);
    line-height: 1.5;

    @include breakpoint($break-medium) {
      font-size: em($h4);
    }

    p {
      margin-top: 0;
      margin-bottom: .75em;
    }
  }
}