.teaser {
  p {
    margin: 0 0 .75em;
  }

  .link-block {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      .title {
        text-decoration: underline;
      }
    }
  }

  img {
    display: inline-block;
  }

  .title {
    font-size: em($h4);
    font-weight: $fw-bold;
    clear: none;
    margin: 1em 0 .25em;

    @include breakpoint($break-large) {
      font-size: em($h3);
    }
  }

}

.teaser--stack {
  @extend .teaser;
}

.teaser--adjacent {
  @extend .teaser;
  @extend %clearfix;

  img {
    @include breakpoint($break-medium) {
      @include span(4 $layout-medium);
    }
  }
  
  .title {
    margin-top: 0;
  }

  @include breakpoint($break-medium) {
    .title, 
    .desc {
      margin-left: span(4 $layout-medium) + gutter($layout-medium);
    }
  }

}

.teaser-quote {
  text-align: center;

  .link-block {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      .quote p {
        text-decoration: underline;
      }
    }
  }

  .tag {
    color: $black-50;
    margin: 0 0 .25em;
    text-transform: uppercase;
    font-size: em($h6);
    line-height: 1.3;
  }

  .quote {
    margin: 0;

    p {
      font-size: em($h3);
      font-family: $ff-secondary;
      font-style: italic;
      line-height: 1.4;
      margin: .25em 0 .75em;
    }

    .name {
      font-style: normal;

      &:before {
        content: "- ";
      }
    }
  }
}