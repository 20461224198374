/**
 * Newsletter signup
 */
.signup-newsletter {
  background-color: scale_color($blue, $lightness: -15%);
  padding: $space $space-half;
  color: white;

  // In context - Main content
  #content & {
    text-align: center;
    @include breakpoint($break-large) {
      padding: $space-double 15%;
    }
  }

  .title {
    font-size: em($h1-narrow);
    font-weight: $fw-bold;
    margin-top: 0;
    margin: 0;
    color: inherit;
    
    @include breakpoint($break-large) {
      font-size: em($h2);
    }
  }

  .desc {
    font-size: em($h4);
    line-height: 1.6;
    p {
      margin: 1.2em 0 0;
    }

    // In context - Main content
    #content & {
      @include breakpoint($break-large) {
        font-size: em($h4);
      }
    }
  }

  .form {
    @extend %clearfix;
    margin-top: 1em;

    .input,
    .btn {
      display: block;
      width: 100%;
      height: 30px;
      border-radius: 0;
    }

    .input {
      border: none;
      padding-left: $space-half;
      @include breakpoint($break-large) {
        #content & {
          width: 70%;
          float: left;
        }
      }
    }

    .btn {
      @extend .btn--cta;

      @include breakpoint($break-large) {
        #content & {
          width: 30%;
          float: left;
        }
      }
    }
  }

  .note {
    margin: .5em 0 0;
    font-size: em($h6);
    color: rgba(255,255,255,.8) ;
  }
}

.campaign-sms {
  @extend .hero--img;
  text-align: center;

  .sidebars & {
    text-align: left;
  }

  .title {
    margin-bottom: 0;
    font-weight: $fw-bold;
    text-align: inherit;

    .sidebars & {
      font-size: em($h3);
    }
  }

  .note {
    margin: 1em 0 0;
    font-size: em($h4);
    @include breakpoint($break-medium) {
      background-color: rgba(0,0,0,.5);
      display: inline-block;
      padding: .5em 2em;
    }

  }
}