.list-accordion {
  list-style: none;
  padding-left: 0;


  li {
    list-style: none !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }

  > li {
    margin-bottom: 1em !important;
  }

  ul {
    padding-left: span(2 $layout-medium);
  }

  .title {
    display: block;
    font-size: em($h4);
    font-weight: $fw-bold;
    
    &:before {
      @extend .icon;
      @extend .icon-chevron-circle-right-blue;
      font-size: 13px;
      margin-top: -2px;
      margin-right: .5em;
      transition: all .2s;
    }
  }

  .is-open {
    &:before {
      transform: rotate(90deg);
    }
  }
}