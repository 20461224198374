.grid {
  @extend %clearfix;

  .grid-item + .grid-item {
    margin-top: gutter();

    @include breakpoint($break-medium) {
      margin-top: gutter($layout-medium);
    }
  }
}

/*doc
---
title: Grid three
name: grid-three
category: Container
  - Grid
---

Goes from one `grid-item` on small screens to three `grid-item`'s on larger screens

Class | Description
:-- | :--
`grid--three` | Used on parent element
`grid-item` | Used on each individual child element of `grid--three`

```html_example
<div class="grid--three">
  <div class="grid-item">Grid column 1. Example text to show how the coluns breaks on smaller screens.</div>
  <div class="grid-item">Grid column 2. Example text to show how the coluns breaks on smaller screens.</div>
  <div class="grid-item">Grid column 3. Example text to show how the coluns breaks on smaller screens.</div>
</div>

```
*/

.grid--three {
  @extend .grid;

  .grid-item {

    @include breakpoint($break-small $break-medium) {
      @include span(4);
      @include push(1);
    }

    @include breakpoint($break-medium) {
      @include span(4 $layout-medium);
      margin-top: 0 !important;
      
      &:nth-child(3) {
        @include last;
      }
    }
  }
}

.grid--stack {
  @extend .grid;
}

.grid--two-one {
  @extend .grid;

  .grid-item {
    + .grid-item {
      @include breakpoint($break-small) {
        margin-top: 0;
      }
    }

    &:first-child {
      @include breakpoint($break-small) {
        @include span(4)
      }

      @include breakpoint($break-medium) {
        @include span(7 $layout-medium);
      }
    }

    &:last-child {
      @include breakpoint($break-small) {
        @include span(2 last);
      }

      @include breakpoint($break-medium) {
        @include span(5 $layout-medium last);
      }
    }
  }
}
